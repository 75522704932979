<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h3>Profil Ayarları</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label class="font-medium text-uppercase">Ad Soyad</label>
          <input type="text" class="form-control" />
        </div>
        <b-row>
          <b-col lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Email Adresi</label>
              <input type="text" class="form-control" />
            </div>
          </b-col>
          <b-col lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Öncelikli GSM No</label>
              <input type="text" class="form-control" />
            </div>
          </b-col>
          <b-col lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Doğum Tarihi</label>
              <input type="text" class="form-control" />
            </div>
          </b-col>
          <b-col lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Cinsiyet</label>
              <input type="text" class="form-control" />
            </div>
          </b-col>
        </b-row>
        <div class="form-group">
          <label class="font-medium text-uppercase">Adres</label>
          <textarea class="form-control"></textarea>
        </div>
        <hr class="ml-n2 mr-n2 mt-2 mb-2" />
        <b-row>
          <b-col lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Önceki Parola</label>
              <input
                type="text"
                class="form-control"
                placeholder="Parolanızı değiştirmek için eski parolanızı yazın"
              />
            </div>
          </b-col>
          <b-col lg="6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Yeni Parola</label>
              <input type="text" class="form-control" />
            </div>
          </b-col>
        </b-row>
        <div class="form-group mt-2">
          <button v-on:click="updateProfile()" class="btn btn-primary">
            Profili Güncelle
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
export default {
  components: {
    vSelect,
    flatPickr,
  },
  created() {
    this.getData();
  },
  data()
  {
      return {
          user:
          {
              user_namesurname:null,
              user_gsm_primary:null,
              user_gsm_secondary:null,
              user_email_primary:null,
              user_email_secondary:null,
              profile_url:null,
              dateofbirth:null,
              address:null,
              gender:null,
          }
      }
  },
  methods: {
    getData() {
      api
        .action("profile", { token: utils.getActiveUser().access_token })
        .then((q) => {
          if(!q.success)
          {
              utils.notificationToast('danger','Bilgi','Sadece kendi profilinizi düzenleyebilirsiniz.');
              return;
          }
          this.user.user_namesurname = q;
        });
    },
    updateProfile() {
 
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>